export default {
    // f2BaseURL: 'http://10.8.1.64:3000',
    f2BaseURL: 'https://fedrogustoautentico.framework2.dev',
    filesApi: '/app-key/fedro/api/files/',
    graphqlURL: '/api/graphql',
    adminurl: '/api/testschema',
    applicationKey: 'fedro',
    adminRoleUid: '0001692699765222-iNWd6CsyZb',
    contentManagerRoleUid: '0001692699791234-bHSuA1TlDo',
};
