// userStore.ts
import {defineStore} from 'pinia';
import {UserInfo} from '../framework2-sdk/f2Api';
import config from '../config';
import {fetchUserByEmail} from '@/api/user';


export interface User {
    ID:string;
    F2_ID:number;
    name: string;
    surname: string;
    email: string;

}


export interface UserState {
    user: null | UserInfo;
}
export interface Credentials {
    username: string;
    password: string;
    applicationKey?: string;
  }

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        user: null,
    } as UserState),
    actions: {
        initUser(userInfo: UserInfo) {
            this.user = userInfo;
        },
        async checkUserExistsByEmail(email: string): Promise<boolean> {
            const fetchedUser = await fetchUserByEmail(email);
            return !!fetchedUser;
        },
    },
    getters: {
        isAdminUser(state) {
            return state.user?.roleSettings[0]?.uid === config.adminRoleUid;
        },
        isContentManagerUser(state) {
            return state.user?.roleSettings[0]?.uid === config.contentManagerRoleUid;
        },
    },
});
