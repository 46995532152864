// userApi.ts
import {getApi} from './apiCommon';
import {UserInfo} from '../framework2-sdk/f2Api';
import config from '../config';


export interface User {
    name: string;
    surname: string;
    email: string;
  }

export async function fetchUserDetails(): Promise<UserInfo | null> {
    const api = getApi();
    if (api) {
        const userInfo: UserInfo = await api.userInfo();
        return userInfo;
    }
    return null;
}

export async function fetchUserByEmail(email: string): Promise<User | null> {
    const api = getApi();
    if (api) {
        const result= await api.callApi({
            url: config.graphqlURL,
            method: 'POST',
            data: {
                query: `query {
                    users(where: {email: "${email}"}) {
                        ID
                        F2_ID
                        name
                        surname
                        email
                    }
                }`,
            },
        });
        return result.data?.users?.pop() || null;
    }
    return null;
}
