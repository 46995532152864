// apiCommon.ts
import {alertController} from '@ionic/vue';
import F2Api, {UserInfo} from '../framework2-sdk/f2Api';
import config from '../config';
import {useUserStore} from '../stores/user';
import {set} from '../framework2-sdk/f2ApiSingleton';
import router from '../router';

let api: Api | null = null;

export type Api = F2Api

export interface Credentials {
  username: string;
  password: string;
  applicationKey?: string;
}

export async function initApi(credentials?: Credentials) {
    try {
        api = new F2Api({
            storage: localStorage,
            onTokenExpired: async function() {
                if (!api) {
                    console.error(new Error('api not set - get'), 'caught');
                    logout();
                    return;
                }
                if (!credentials) {
                    const alert = await alertController.create({
                        buttons: ['Login'],
                        inputs: [
                            {
                                placeholder: 'Username',
                                attributes: {
                                    required: true,
                                },
                            },
                            {
                                placeholder: 'password',
                                attributes: {
                                    required: true,
                                    maxlength: 128,
                                    minlength: 8,
                                    type: 'password',
                                },
                            },
                        ],
                    });
                    await alert.present();
                    const {data} = await alert.onDidDismiss();
                    credentials = {
                        username: data.values[0],
                        password: data.values[1],
                    };
                }
                await api.login(
                    credentials.username,
                    credentials.password,
                    config.applicationKey,
                    true,
                );
            },
            onTokenExpiredFails: async function(e: Error) {
                console.error(e, 'caught', 'api - onTokenExpiredFails');
                logout();
            },
        }) as Api;

        set(api);
        api.setBaseUrl(config.f2BaseURL);

        if (credentials) {
            await api.login(
                credentials.username,
                credentials.password,
                config.applicationKey,
                true,
            );
        } else if (api.isTokenExpired()) {
            return null;
        }

        const userInfo: UserInfo = await api.userInfo();
        const userStore = useUserStore();
        userStore.initUser(userInfo);

        return api;
    } catch (e) {
        api = null;
        throw e;
    }
}

export function logout() {
    api = null;
    set(api);
    router.replace('/login');
}

export function getApi() {
    if (api) {
        return api;
    } else {
        console.error(new Error('api not set - get'), 'caught');
        logout();
    }
}
