import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import TabsPage from '@/views/TabsPage.vue';
import {useUserStore} from '@/stores/user';
import {initApi} from '@/api/apiCommon';
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/app-intro',
    },

    {
        path: '/app-intro',
        component: () => import('@/shared/components/AppIntro.vue'),
        name: 'intro',
    },
    {
        path: '/login',
        component: () => import('@/shared/views/LoginPage.vue'),
        name: 'login',
    },
    {
        path: '/register',
        component: () => import('@/views/RegisterPage.vue'),
        name: 'register',
    },
    {
        path: '/:language/confirm-email/:token',
        component: () => import('@/views/ConfirmEmailPage.vue'),
        props: true,
        name: 'confirmEmail',
    },
    {
        path: '/tabs/',
        component: TabsPage,
        name: 'tabs',
        children: [
            {
                path: '',
                redirect: '/tabs/company-list',
                name: 'default-tab',

            },
            {
                path: 'company-list',
                component: () => import('@/views/SupplierPage.vue'),
            },
            {
                path: 'customer-list',
                component: () => import('@/views/CustomerPage.vue'), // Make sure this view exists!
            },
            {
                path: 'product-list',
                component: () => import('@/views/ProductPage.vue'), // Add this new route
            },
            {
                path: 'post-list',
                component: () => import('@/views/PostPage.vue'), // Add this new route
            },
            {
                path: '/profile-view/single-post/:postId',
                component: () => import('../components/ApprovePost.vue'),
            },
            {
                path: 'order-list',
                component: () => import('@/views/OrderPage.vue'), // Add this new route
            },
            {
                path: 'data-management',
                component: () => import('@/views/DataManagementPage.vue'), // Adjust the path as necessary
            },
            {
                path: '/edit-post/:postId',
                name: 'EditPost',
                component: () => import('@/views/EditPostView.vue'),
                props: true,
            },


        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const store = useUserStore();
    // TODO initApi without credentials and check if it returns not null -> saved api token is valid
    //
    if (
        !store.user &&
        (to.name === 'tabs' || to.matched[0]?.name === 'tabs') &&
        ((await initApi())==null)
    ) {
        next({path: '/'});
    }
    next();
});

export default router;
